<template>
<div id="hiddenbody" :style="{background: `'radial-gradient(#0b3513, #041607), url(../assets/styles/hidden/giphy.webp)'` }">
  <div class="noise"> </div>
  <div class="overlay"> </div>
  <div class="terminal">
    <h1>Error <span class="errorcode">801</span></h1>
    <p class="output">Congratulations!</p>
    <p class="output">You've broken the internet! :-P</p>
    <p class="output">You can choose to: <a class="ms-5" @click="$router.push('/')">fix this</a> </p>
    <!-- <p class="output">..or, if you want: <a class="ms-5" @click="$router.push('/')">go back</a> </p> -->
    <p class="output">Maybe you want to: <a class="ms-5" href="mailto: me@modestcoder.org">mail me</a></p>
    <p class="output">Or see some old stuff: <a class="ms-5" @click="$router.push('/other')">fun</a></p>
    <p class="output">Thank you anyway.</p>
  </div>
</div>
</template>
<script>
export default ({
    name: 'hidden',
    data(){ return { 
        
    } }
})
</script>
<style scoped>
    
  @font-face {
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    src: local('Inconsolata Regular'), local('Inconsolata-Regular'), url('../assets/styles/hidden/inconsolata.woff2') format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

  @font-face {
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    src: local('Inconsolata Regular'), local('Inconsolata-Regular'), url('../assets/styles/hidden/inconsolata.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

  @font-face {
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    src: local('Inconsolata Regular'), local('Inconsolata-Regular'), url('../assets/styles/hidden/inconsolata.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html, body {
    height: 100vh;
    width: 100vw;
    background-image: radial-gradient(#0b3513, #041607), url("../assets/styles/hidden/giphy.webp");
    background-repeat: no-repeat;
    background-size: auto;
}

#hiddenbody {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  font-family: 'Inconsolata', Helvetica, sans-serif;
  font-size: 1.5rem;
  color: rgba(128, 255, 128, 0.8);
  text-shadow:
          0 0 1ex rgba(51, 255, 51, 1),
          0 0 2px rgba(255, 255, 255, 0.8);
}

.noise {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../assets/styles/hidden/giphy.webp");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  opacity: .02;
}

.overlay {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background:
          repeating-linear-gradient(
              180deg,
            rgba(0, 0, 0, 0) 0,
            rgba(0, 0, 0, 0.3) 50%,
            rgba(0, 0, 0, 0) 100%);
  background-size: auto 4px;
  z-index: 1;
}

.overlay::before {
  content: "";
  pointer-events: none;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      0deg,
      transparent 0%,
    rgba(32, 128, 32, 0.2) 2%,
    rgba(32, 128, 32, 0.8) 3%,
    rgba(32, 128, 32, 0.2) 3%,
      transparent 100%);
  background-repeat: no-repeat;
  animation: scan 7.5s linear 0s infinite;
}

@keyframes scan {
  0%        { background-position: 0 -100vh; }
  35%, 100% { background-position: 0 100vh; }
}

.terminal {
  box-sizing: inherit;
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 10rem;
  margin-top: 5%;
  margin-left: 20%;
  margin-right: 20%;
  text-transform: uppercase;
}

.terminal a {
  cursor: pointer;
}

.output {
  color: rgba(128, 255, 128, 0.8);
  text-shadow:
          0 0 1px rgba(51, 255, 51, 0.4),
          0 0 2px rgba(255, 255, 255, 0.8);
}

.output::before {
    content: "> ";
}

a {
  color: #fff;
  text-decoration: none;
}

a::before {
    content: "[";
}

a::after {
    content: "]";
}

.errorcode {
    color: white;
}

@media (max-width: 720px){
  .terminal {
    padding: 0;
    margin: auto 10%;
    font-size: 1.2rem;
    transform: scale(.75);
    transition: transform 3s;
  }
}

</style>